import { Controller, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import clsx from "clsx";

import { Button, IconButton, SwipeableDrawer, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LinkOffIcon from '@mui/icons-material/LinkOff';
import moment from 'moment';

import { Flex, Label } from "@ais/palette";
import styles from "@ais/forms/src/V2/FieldComponents/TbLink/TbLinkDrawer.module.css";
import TbLinkSelect from "@ais/forms/src/V2/FieldComponents/TbLink/TbLinkSelect";
import TbLinkDropdown from "@ais/forms/src/V2/FieldComponents/TbLink/TbLinkDropdown";
import TbLinkRadioButtonList from '@ais/forms/src/V2/FieldComponents/TbLink/TbLinkRadioButtonList';
import {
    FilterTrialBalances,
    FilterValidTrialBalances,
    GetTrialBalanceFromTrialBalanceSupportingValue,
    IsAnswerTrialBalance,
    EvaluateFundIndexOptions,
    ConstructAnswerPayload,
    FormatTbPeriodDate,
    TYPE_MENU_ITEMS,
    FUND_LEVEL_MENU_ITEMS,
    SIGN_OPTIONS
} from "@ais/forms/src/V2/FieldComponents/TbLink/TbLinkHelper";

import { useProjectFormInstanceProviderV2 } from '@providers';
import { useTrialBalances } from "@services/trialbalance";

const TbLinkDrawer = ({ value, open, setOpen, id, onChange, project, onSubmit, onFocus, isDisabled, unlockField, isIdle }) => {
    const { control } = useFormContext();
    const { balances, detailedTrialBalances, actions: { updateDetailedTrialBalances } } = useProjectFormInstanceProviderV2();

    const closeDrawer = () => {
        setOpen(false);
        unlockField && unlockField();
    }
    const Placeholder = "Select";
    const parsedValue = GetTrialBalanceFromTrialBalanceSupportingValue(value);
    const answerIsTrialBalance = IsAnswerTrialBalance(value);

    //Available TB Data
    const [trialBalanceOptions, setTrialBalanceOptions] = useState([]);//TB Select Options
    const [trialBalanceOptionIds, setTrialBalanceOptionIds] = useState([]);//Available TBs which get queried in call below
    const trialBalanceRecordsQuery = useTrialBalances(trialBalanceOptionIds, project?.ProjectId, detailedTrialBalances.length !== 0);
    const [detailedTrialBalancesRetrieved, setDetailedTrialBalancesRetrieved] = useState(false);
    const [applicableFundLevel, setApplicableFundLevel] = useState(false);

    //Selected TB Data
    const [selectedTrialBalances, setSelectedTrialBalances] = useState(parsedValue?.correlationNameIds ?? []);
    const [selectedType, setSelectedType] = useState(parsedValue?.type ?? '');
    const [selectedGroupAccountOption, setSelectedGroupAccountOption] = useState(parsedValue.groupAccount ?? '');
    const [selectedPeriod, setSelectedPeriod] = useState(parsedValue?.period ?? '');
    const [selectedBalanceType, setSelectedBalanceType] = useState(parsedValue?.balanceType ?? '');
    const [selectedFundLevel, setSelectedFundLevel] = useState(parsedValue?.fundLevel ?? '');
    const [selectedFundIndex, setSelectedFundIndex] = useState(parsedValue?.fundIndex ?? '');
    const [selectedSignOptions, setSelectedSignOptions] = useState(parsedValue?.signOptions ?? SIGN_OPTIONS.ACTUAL_SIGN);

    //Related TB Data (option sets)    
    const [groupAccountOptions, setGroupAccountOptions] = useState([]);
    const [periodOptions, setPeriodOptions] = useState([]);
    const [balanceTypeOptions, setBalanceTypeOptions] = useState([]);
    const [fundLevelOptions] = useState(FUND_LEVEL_MENU_ITEMS);
    const [fundIndexOptions, setFundIndexOptions] = useState([]);

    //Validation
    const [submittable, setSubmittable] = useState(false);
    const [isCleared, setIsCleared] = useState(false);

    //Functions to manage TB Data
    const changeSelectedTbs = (values) => {
        //Looks funny, but done to support V1 to V2 values (v1 values returned come in as the object, converted to string, then seperated into an array by each character)
        //Awful, but V2 does not do this, this is to account for "legacy data" issue. Overall answer object is the same structure so flows throughout rest of app as expected.
        const changedTbs = values.filter(value => typeof value === 'object');
        updateSelectedTrialBalances(changedTbs);
        setIsCleared(false);
    }

    const getSelectedTbs = () => {
        return detailedTrialBalances.filter(detailedTB => selectedTrialBalances.includes(detailedTB.id)).map(detailedTB => detailedTB.correlationNameId);
    }

    const getSelectedTbsCorrelationDetailIds = () => {
        return detailedTrialBalances.filter(detailedTB => selectedTrialBalances.includes(detailedTB.id)).map(detailedTB => detailedTB.correlationDetailId);
    }

    const changeSelectedType = (value) => {
        setSelectedGroupAccountOption('');
        setGroupAccountOptions(evaluateGroupAccountOptions(value));
        setSelectedType(value);
    }

    const changeSelectedPeriod = (value) => {
        setSelectedPeriod(value);
        setSelectedBalanceType('');//balance type availability can change based on period
    }

    const changeSelectedBalanceType = (value) => {
        setSelectedBalanceType(value);
    }

    const changeSelectedFundLevel = (value) => {
        setSelectedFundLevel(value);
        setSelectedFundIndex('');
        setFundIndexOptions(getFundIndexOptions(value));
    }

    const changeSelectedSignOptions = (event) => {
        const { target: { value } } = event;
        setSelectedSignOptions(value);
    }

    const changeSelectedFundIndex = (value) => {
        setSelectedFundIndex(value);
    }

    const evaluateFundLevelApplicability = () => {
        const targetCorrelationNameIds = getSelectedTbs();
        const trialBalancesGroups = detailedTrialBalances
            .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
            .flatMap((tb) => tb.groups);
        const filteredIndexes = trialBalancesGroups.filter((group) => {
            if (group["segment03"] || group["segment04"] || group["segment05"]) {
                return true
            }
            return false
        })
        return filteredIndexes.length > 0;
    }

    const getPeriodOptionsFormatted = () => {
        /*Use the selectedTrialBalances to get affiliated correlationNameIds
        then filter for options where they equal that rather than id (otherwise it'll only be 1 TB specifically and not support multiple versions/years)
        */
        const targetCorrelationNameIds = getSelectedTbs();
        const sortedDetailedTrialBalances = [...detailedTrialBalances].sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
        const uniquePeriods = [
            ...new Set(
                sortedDetailedTrialBalances
                    .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                    .map(tb => {
                        return `${moment(tb.startDate).format("L")} - ${moment(tb.endDate).format("L")}`;
                    })
            )
        ];
        return uniquePeriods.map(option => { return { value: option, label: option } });
    }

    const getBalanceTypeOptions = () => {
        const targetCorrelationNameIds = getSelectedTbs();

        if (selectedPeriod) {
            //Ensure that if a period is selected already, the balance types are filtered to only show ones of that period
            const [startDate, endDate] = selectedPeriod.replaceAll(' ', '').split('-').map(FormatTbPeriodDate);
            const uniqueBalanceTypes = [
                ...new Set(
                    detailedTrialBalances
                        .filter(
                            detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId) &&
                                detailedTB.startDate === startDate &&
                                detailedTB.endDate === endDate
                        )
                        .map(tb => tb.trialBalanceType)
                )
            ];
            return uniqueBalanceTypes.map(option => { return { value: option, label: option } });
        } else {
            const uniqueBalanceTypes = [
                ...new Set(
                    detailedTrialBalances
                        .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                        .map(tb => tb.trialBalanceType)
                )
            ];
            return uniqueBalanceTypes.map(option => { return { value: option, label: option } });
        }
    }

    const getFundIndexOptions = (value) => {
        const targetCorrelationNameIds = getSelectedTbs();
        const targetKey = EvaluateFundIndexOptions(value);
        const uniqueFundIndexes = [
            ...new Set(
                detailedTrialBalances
                    .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                    .flatMap(detailedTb =>
                        detailedTb.groups.filter(group => group[targetKey] !== null).map(group => group[targetKey])
                    )
            )
        ];
        return uniqueFundIndexes.map(option => { return { value: option, label: option } });
    }

    const evaluateGroupAccountOptions = (values) => {
        const targetCorrelationNameIds = getSelectedTbs();
        switch (values) {
            case 'account':
                const uniqueAccountTypes = [
                    ...new Set(
                        detailedTrialBalances
                            .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                            .flatMap(detailedTb =>
                                detailedTb.groups.filter(group => group.account !== null).map(group => group.account))
                    )
                ];
                return uniqueAccountTypes.sort().map(option => { return { value: option, label: option } });
            case 'accountSubType':
                const uniqueAccountSubCategoryTypes = [
                    ...new Set(
                        detailedTrialBalances
                            .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                            .flatMap(detailedTb =>
                                detailedTb.groups.filter(group => group.accountSubType !== null).map(group => group.accountSubType))
                    )
                ];
                return uniqueAccountSubCategoryTypes.sort().map(option => { return { value: option, label: option } });
            case 'fsCaption':
                const uniqueCategories = [
                    ...new Set(
                        detailedTrialBalances
                            .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                            .flatMap(detailedTb =>
                                detailedTb.groups.filter(group => group.fsCaption !== null).map(group => group.fsCaption))
                    )
                ];
                return uniqueCategories.sort().map(option => { return { value: option, label: option } });
            case 'accountName':
                const uniqueAccountSubCategories = [
                    ...new Set(
                        detailedTrialBalances
                            .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                            .flatMap(detailedTb =>
                                detailedTb.groups.filter(group => group.accountName !== null).map(group => group.accountName))
                    )
                ];
                return uniqueAccountSubCategories.sort().map(option => { return { value: option, label: option } });
            case 'glAccountNumber':
                const uniqueGlAccounts = [
                    ...new Set(
                        detailedTrialBalances
                            .filter(detailedTB => targetCorrelationNameIds.includes(detailedTB.correlationNameId))
                            .flatMap(detailedTb =>
                                detailedTb.groups.filter(group => group.glAccountNumber !== null).map(group => group.glAccountNumber + " " + group.glAccountName))
                    )
                ];
                return uniqueGlAccounts.sort().map(option => { return { value: option, label: option } });
            default:
                return [];
        }
    }

    const changeSelectedGroups = (value) => {
        setSelectedGroupAccountOption(value);
    }

    const updateSelectedTrialBalances = (correlationNameIds) => {
        //This pulls in the value being changed. This function determines if the change is to add or remove based on state.
        const updatedSelectedTrialBalances = [...selectedTrialBalances];
        correlationNameIds.forEach(correlation => {
            const index = updatedSelectedTrialBalances.indexOf(correlation.id)
            if (index > -1) {
                updatedSelectedTrialBalances.splice(index, 1);
            } else {
                updatedSelectedTrialBalances.push(correlation.id);
            }
        });
        setSelectedTrialBalances(updatedSelectedTrialBalances);
    }

    const removeTbLink = () => {
        setSelectedTrialBalances([]);
        setSelectedType('');
        setSelectedGroupAccountOption('');
        setSelectedPeriod('');
        setSelectedBalanceType('');
        setSelectedFundLevel('');
        setSelectedFundIndex('');
        setIsCleared(true);
    }

    const evaluateSubmittable = () => {
        //Verify that all dropdowns have a selection
        if (selectedTrialBalances.length === 0 || selectedType === '' || selectedGroupAccountOption === '' || selectedPeriod === '' || selectedBalanceType === '') {
            setSubmittable(isCleared ? true : false);//If empty due to TB Link being broken, allow submission
        } else {
            //Verify that current selections are different from the original parsed value (array comparison as well for selectedTrialBalances)
            //If answer is not a TB, resolve to false to allow submission of new TB link answer
            const selectedDataIsEqualToInitialData = answerIsTrialBalance ?
                (selectedTrialBalances.length === parsedValue.correlationNameIds.length && selectedTrialBalances.every((value, index) => value === parsedValue.correlationNameIds[index])) &&
                selectedType === parsedValue.type &&
                selectedGroupAccountOption === parsedValue.groupAccount &&
                selectedPeriod === parsedValue.period &&
                selectedBalanceType === parsedValue.balanceType &&
                selectedFundLevel === parsedValue.fundLevel &&
                selectedFundIndex === parsedValue.fundIndex &&
                selectedSignOptions === parsedValue.signOptions : false;

            if (selectedDataIsEqualToInitialData) {
                setSubmittable(false);
            }
            else {
                setSubmittable(true);
            }
        }
    }

    const handleSave = () => {
        if (isIdle) return;
        if (isCleared) {
            onChange('');
        } else {
            const selectedData = getSelectedData();
            const payload = ConstructAnswerPayload(selectedData, detailedTrialBalances);
            onChange(JSON.stringify(payload));
        }
        onSubmit(id);
        closeDrawer();
        unlockField && unlockField();
    }

    const getSelectedData = () => {
        const selectedData = {
            "correlationNameIds": selectedTrialBalances,
            "type": selectedType,
            "groupAccount": selectedGroupAccountOption,
            "period": selectedPeriod,
            "balanceType": selectedBalanceType,
            "fundLevel": selectedFundLevel,
            "fundIndex": selectedFundIndex,
            "signOptions": selectedSignOptions,
            "correlationDetailIds": getSelectedTbsCorrelationDetailIds()
        }

        return selectedData;
    }

    useEffect(() => {
        if (!balances) return;
        const filteredBalanceSelectOptionSet = FilterTrialBalances(balances, selectedTrialBalances);
        const filteredBalances = FilterValidTrialBalances(balances);
        /*Important note, many tests have invalid ingestions (missing correlationName for example)
        This only allows users to select valid ingestions, but v1 behavior also drives off invalid ingestions.
        For example, if user has a fund tb ingested that was invalid due to missing correlation name, the UI in V1 will still display the fund options available
        DESPITE the fact that the TB is invalid and not even displayed for selection on drop down. This is a bug in V1, which will not be carried over to V2.
        If we want to introduce this bug as a feature, simply swap the commented out line below with the line above it.
        See Kevin's home alone enterprises 2023_2023 project for an example of this behavior.
        */
        setTrialBalanceOptions(filteredBalanceSelectOptionSet);
        setTrialBalanceOptionIds(filteredBalances.map((balance) => balance.id));
        //setTrialBalanceOptions(balances);
        //setTrialBalanceOptionIds(balances.map((balance) => balance.id));
        setDetailedTrialBalancesRetrieved(false);
    }, [balances, open]);

    useEffect(() => {
        if ((trialBalanceRecordsQuery.length > 0 && trialBalanceRecordsQuery.every((tbQuery) => tbQuery.isFetching === false && tbQuery.isFetched === true) && !detailedTrialBalancesRetrieved)) {
            setDetailedTrialBalancesRetrieved(true);
            //The process below basically lines up the detailedTrialBalance data response to incorporate the correlationNameId (available on the list of TBs response, but not on individual tb response)
            //This is crucial in order to support V1 behavior (you have 5 ingestions for 1 TB, but see 1 TB in dropdown, but other selections you make determine which of the 5 you really wanted...)
            const detailedTrialBalanceData = trialBalanceRecordsQuery.map((tbQuery) => tbQuery.data);
            const balancesMap = new Map();
            balances.forEach(balance => {
                balancesMap.set(balance.id, balance.correlationNameId);
            });
            const updatedTrialBalances = detailedTrialBalanceData.map(tb => {
                if (balancesMap.has(tb.id)) {
                    return { ...tb, correlationNameId: balancesMap.get(tb.id) };
                }
                return tb;
            });
            updateDetailedTrialBalances(updatedTrialBalances);
        }
    }, [trialBalanceRecordsQuery]);

    useEffect(() => {
        //Initializer here to get rest of drop down options once this is done since data drives off the detailed trial balances
        setGroupAccountOptions(evaluateGroupAccountOptions(selectedType));
        setPeriodOptions(getPeriodOptionsFormatted());
        setBalanceTypeOptions(getBalanceTypeOptions());
        setApplicableFundLevel(evaluateFundLevelApplicability());
        setFundIndexOptions(getFundIndexOptions(selectedFundLevel));
    }, [detailedTrialBalances, selectedTrialBalances]);

    useEffect(() => {
        //If user changes period, ensure balance types are updated to reflect the new period balance type availability
        setBalanceTypeOptions(getBalanceTypeOptions());
    }, [selectedPeriod]);

    useEffect(() => {
        //When dropdowns change, determine if the save button should be enabled
        evaluateSubmittable();
    }, [selectedTrialBalances, selectedType, selectedGroupAccountOption, selectedPeriod, selectedBalanceType]);

    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={closeDrawer}
            onOpen={() => { }}
            sx={{
                width: "50%",
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {
                    width: "50%",
                    backgroundColor: "#E4E0E0"
                }
            }}
        >
            <Controller
                key={id}
                control={control}
                name={id}
                render={({ field: { value } }) => {
                    return (
                        //use onChange to update the value of the field and check event.target.value to ensure proper json structure
                        <>
                            <Flex className={styles["drawer__close-button"]}>
                                <Flex direction="unset" className={styles["drawer__content__icon-container"]}>
                                    <IconButton
                                        variant="close-drawer-button"
                                        onClick={closeDrawer}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Flex>
                            </Flex>

                            <Flex className={styles["drawer__container"]}>
                                <Label className={styles["drawer__label"]} size="md" weight="medium">Link Trial Balance Answer</Label>
                                <Flex className={styles["drawer__content"]}>
                                    <Flex direction="row" className={styles["drawer__content__actions"]}>
                                        <div
                                            data-testid="LinkOffIcon"
                                            onClick={!answerIsTrialBalance ? null : removeTbLink}
                                            className={`${styles.linkOffIcon} ${!answerIsTrialBalance || isDisabled ? styles.disabled : ''}`}
                                        >
                                            <LinkOffIcon />
                                            <Typography variant="caption">
                                                Remove TB Link
                                            </Typography>
                                        </div>
                                        <Flex className={styles["drawer__content__cancel-button"]}>
                                            <Button
                                                variant="outlined"
                                                onClick={closeDrawer}
                                            >
                                                Cancel
                                            </Button>
                                        </Flex>
                                        <Flex className={styles["drawer__content__submit-button"]}>
                                            <Button
                                                variant="contained"
                                                type="button"
                                                onClick={handleSave}
                                                disabled={isDisabled || !submittable}
                                            >
                                                Save
                                            </Button>
                                        </Flex>
                                    </Flex>

                                    <Flex className={styles["drawer__content__row__header"]} direction="row">
                                        <TbLinkSelect
                                            id={id}
                                            name={id}
                                            key={id}
                                            label="Trial Balance"
                                            onChange={changeSelectedTbs}
                                            value={selectedTrialBalances}
                                            onFocus={onFocus}
                                            isDisabled={isDisabled}
                                            menuItems={trialBalanceOptions}
                                            allowExternalAccess={false}
                                            placeholder={Placeholder}
                                        />
                                    </Flex>

                                    <Flex className={styles["drawer__content__row"]} direction="row">
                                        <TbLinkDropdown
                                            id={id}
                                            name={id}
                                            key={`${id}_type`}
                                            label="Type"
                                            menuItems={TYPE_MENU_ITEMS}
                                            onChange={changeSelectedType}
                                            isDisabled={selectedTrialBalances.length === 0 || isDisabled}
                                            placeholder={Placeholder}
                                            onFocus={onFocus}
                                            value={selectedType}
                                        />

                                        <TbLinkDropdown
                                            id={id}
                                            name={id}
                                            key={`${id}_groupAccount`}
                                            label="Group / Account"
                                            menuItems={groupAccountOptions}
                                            onChange={changeSelectedGroups}
                                            isDisabled={selectedTrialBalances.length === 0 || isDisabled}
                                            placeholder={Placeholder}
                                            onFocus={onFocus}
                                            value={selectedGroupAccountOption}
                                        />

                                    </Flex>

                                    <Flex className={styles["drawer__content__row"]} direction="row">
                                        <TbLinkDropdown
                                            id={id}
                                            name={id}
                                            key={`${id}_period`}
                                            label="Period"
                                            menuItems={periodOptions}
                                            onChange={changeSelectedPeriod}
                                            isDisabled={selectedTrialBalances.length === 0 || isDisabled}
                                            placeholder={Placeholder}
                                            onFocus={onFocus}
                                            value={selectedPeriod}
                                        />

                                        <TbLinkDropdown
                                            id={id}
                                            name={id}
                                            key={`${id}_balanceType`}
                                            label="Balance Type"
                                            menuItems={balanceTypeOptions}
                                            onChange={changeSelectedBalanceType}
                                            isDisabled={selectedTrialBalances.length === 0 || isDisabled}
                                            placeholder={Placeholder}
                                            onFocus={onFocus}
                                            value={selectedBalanceType}
                                        />

                                    </Flex>

                                    <Flex className={styles["drawer__content__row"]} direction="row">
                                        <TbLinkDropdown
                                            id={id}
                                            name={id}
                                            key={`${id}_fundLevel`}
                                            label="Fund Level"
                                            menuItems={fundLevelOptions}
                                            onChange={changeSelectedFundLevel}
                                            isDisabled={applicableFundLevel === false || isDisabled}
                                            placeholder={Placeholder}
                                            onFocus={onFocus}
                                            value={selectedFundLevel}
                                        />

                                        <TbLinkDropdown
                                            id={id}
                                            name={id}
                                            key={`${id}_fundIndex`}
                                            label="Fund Index"
                                            menuItems={fundIndexOptions}
                                            onChange={changeSelectedFundIndex}
                                            isDisabled={applicableFundLevel === false || isDisabled}
                                            placeholder={Placeholder}
                                            onFocus={onFocus}
                                            value={selectedFundIndex}
                                        />

                                    </Flex>

                                    <Flex className={clsx(styles["drawer__content__row"], styles["drawer__content__sign-options"])} direction="column">
                                        <TbLinkRadioButtonList
                                            id={id}
                                            name={id}
                                            key={`${id}_signOptions`}
                                            label="Sign Options"
                                            columns={2}
                                            options={[SIGN_OPTIONS.ACTUAL_SIGN, SIGN_OPTIONS.REVERSE_SIGN]}
                                            value={selectedSignOptions}
                                            onChange={changeSelectedSignOptions}
                                            isDisabled={isDisabled}
                                        />
                                    </Flex>

                                </Flex>
                            </Flex>
                        </>
                    )
                }
                }
            >

            </Controller>
        </SwipeableDrawer>
    )
}

export default TbLinkDrawer;